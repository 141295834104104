const baseErrors = require('./base').RESPONSE_CODES;

export const RESPONSE_CODES = Object.freeze({
    ...baseErrors,
    NO_DIRECTIONS: 4,
    USER_IS_ALREADY_A_PRODUCER: 5,
    USER_PASSWORD_INVALID: 6,
    VALIDATION_NOT_DONE: 7,
    EMAIL_ALREADY_REGISTERED: 8,
    EMAIL_UNABLE_TO_VERIFY: 9,
    FORM_NOT_VALID: 10,
    UNABLE_STORE_DIRECTION: 11,
    POSITION_NOT_AVAILABLE: 12,
})

export const RESPONSE_MESSAGES = Object.freeze({
    [RESPONSE_CODES.OK]: '$common.codes.user.ok',
    [RESPONSE_CODES.ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.UNKNOWN_ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.USER_PASSWORD_INVALID]: '$common.codes.user.userPasswordInvalid',
    [RESPONSE_CODES.VALIDATION_NOT_DONE]: '$common.codes.user.validationNotDone',
    [RESPONSE_CODES.EMAIL_ALREADY_REGISTERED]: '$common.codes.user.emailExists',
    [RESPONSE_CODES.EMAIL_UNABLE_TO_VERIFY]: '$common.codes.user.emailUnableVerify',
})