/*
 Be aware, this codes are hardcoded in trigger match expressions!
*/

const TYPES = Object.freeze({
    ORDER_PAYIN: 0,
    ORDER_PENDING: 1,
    ORDER_IN_TRANSIT: 2,
    ORDER_SOLVE: 3
})

export {
    TYPES
}