import * as formvalidator from './module';

const Errors = formvalidator.Errors
const Regexs = formvalidator.Regexs

const productAttributeErrorsStrings = {
    images: {
        list: {
            [Errors.MAX_ARRAY_LENGTH]: '$productForm.errorsDescription.imageNumberExceded',
            [Errors.MIN_ARRAY_LENGTH]: '$productForm.errorsDescription.imageMinRequired',
            [Errors.NO_MATCH]: '$productForm.errorsDescription.invalidImage',
        }
    },
    labels: {
        [Errors.WRONG_VALUE]: '$productForm.errorsDescription.invalidTags'
    },
    category: {
        [Errors.WRONG_VALUE]: '$productForm.errorsDescription.invalidCategory'
    },
    salePrice: {
        [Errors.VALUE_MAX_LIMIT]: '$productForm.errorsDescription.priceSaleMustBeLowerThanPrice'
    },
    price: {
        [Errors.VALUE_MIN_LIMIT]: '$productForm.errorsDescription.priceMustBeHigherThan0'
    },
    packUnits: {
        [Errors.VALUE_MIN_LIMIT]: '$productForm.errorsDescription.packUnitsMustBeHigherThan1'
    },
    amount: {
        [Errors.VALUE_MIN_LIMIT]: '$productForm.errorsDescription.amountMustBeHigherThan0'
    },
    amountMax: {
        [Errors.VALUE_MIN_LIMIT]: '$productForm.errorsDescription.amountMaxMustBeHigherThanAmount'
    }
}
const productFormValidationDependencies = {
    "priceType": {
        "salePrice": true,
        "price": true
    },
    "salePrice": {
        "priceType": true,
        "price": true
    },
    "priceUnit": {
        "packUnits": true
    },
    "packUnits": {
        "priceUnit": true
    },
    "amountType": {
        "amountMax": true,
        "amount": true
    },
    "amountMax": {
        "amountType": true,
        "amount": true
    }
}
const productAttributeValidators = {
    priceType: formvalidator.isInArray(['ONSALE', 'DEFAULT']),
    price: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: true }),
    salePrice: formvalidator.condition({
        if: (value, form) => form['priceType'] == 'ONSALE',
        then: (value, form) => formvalidator.isDecimalNumberValid({ min: 0, max: form['price'] })(value, form),
        else: () => Errors.NO_ERROR
    }),
    priceUnit: formvalidator.isInArray(['U', 'PKG']),
    packUnits: formvalidator.condition({
        if: (value, form) => form['priceUnit'] == 'PKG',
        then: formvalidator.isNumberValid({ min: 2 }),
        else: () => Errors.NO_ERROR
    }),
    version: formvalidator.alwaysValid,
    amount: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: true }),
    amountType: formvalidator.isInArray(['RANGE', 'EXACT']),
    amountMax: formvalidator.condition({
        if: (value, form) => form['amountType'] == 'RANGE',
        then: (value, form) => formvalidator.isDecimalNumberValid({ min: form['amount'], minEqFail: true })(value, form),
        else: () => Errors.NO_ERROR
    }),
    amountUnit: formvalidator.isInArray(['U', 'MG', 'G', 'KG', 'ML', 'CL', 'L']),
    weight: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: true }),
    weightUnit: formvalidator.isInArray(['MG', 'G', 'KG']),
    stock: formvalidator.isNumberValid({ min: 0, minEqFail: true }),
    images: formvalidator.isObjectValid({
        //stared : formvalidator.isObjectIdValid,
        list: formvalidator.isArrayValid(formvalidator.isObjectIdValid, 1, 5)
    }),
    translations: formvalidator.isTranslationValid({
        name: formvalidator.isTextValid(),
        description: formvalidator.isTextValid()
    }),
    category: formvalidator.isObjectIdValid,
    labels: formvalidator.isArrayValid(formvalidator.isObjectIdValid, 0),
    allergens: formvalidator.isArrayValid(formvalidator.isTextValid(), 0),
    ownMade: formvalidator.isBooleanValid,
    isDeleted: formvalidator.isBooleanValid,
    isDisabled: formvalidator.isBooleanValid
}

const producerAttributeErrorsStrings = {
    images: {
        list: {
            [Errors.MAX_ARRAY_LENGTH]: '$profile.form.errorsDescription.imageNumberExceded',
            [Errors.MIN_ARRAY_LENGTH]: '$profile.form.errorsDescription.imageMinRequired',
            [Errors.NO_MATCH]: '$profile.form.errorsDescription.invalidImage',
        },
        profile: {
            [Errors.NO_MATCH]: '$profile.form.errorsDescription.invalidImage',
        },
        cover: {
            [Errors.NO_MATCH]: '$profile.form.errorsDescription.invalidImage',
        }
    },
    location: {
        coordinates: {
            [Errors.MAX_ARRAY_LENGTH]: '$profile.form.errorsDescription.invalidCoordinates',
            [Errors.MIN_ARRAY_LENGTH]: '$profile.form.errorsDescription.invalidCoordinates',
        }
    }
}

const kycAttributeErrorsStrings = {
    ubos: {
        Birthday: {
            [Errors.WRONG_VALUE]: '$finances.kycForm.invalidDate',
        }
    },
}

const kycAttributeValidators = {
    ubos: formvalidator.isArrayValid(formvalidator.isObjectValid({
        FirstName: formvalidator.isNameValid,
        LastName: formvalidator.isNameValid,
        Address: formvalidator.isObjectValid({
            AddressLine1: formvalidator.isStreetValid,
            City: formvalidator.isStreetValid,
            PostalCode: formvalidator.isRegexValid(Regexs.postalCode),
            Country: formvalidator.isTextValid()
        }),
        Nationality: formvalidator.isTextValid(),
        Birthday: formvalidator.isDateValid(18),
        Birthplace: formvalidator.isObjectValid({
            City: formvalidator.isStreetValid,
            Country: formvalidator.isStreetValid
        }),
    }))
}

const producerPickupAttributeValidators = {
    name: formvalidator.isTextValid(Regexs.all, 1, 50),
    days: formvalidator.isArrayValid(formvalidator.isObjectValid({
        day: formvalidator.isInArray([0, 1, 2, 3, 4, 5, 6]),
        times: formvalidator.isArrayValid(formvalidator.condition({
            if: (value, form) => {
                return typeof value['end'] !== 'undefined' && typeof value['end'] !== 'undefined' &&
                    (value['end'] < value['start'] || value['start'] > value['end'])
            },
            then: () => Errors.WRONG_VALUE,
            else: () => Errors.NO_ERROR
        }), 0),
    })),
    isEnabled: formvalidator.isBooleanValid,
    location: formvalidator.isObjectValid({
        postal_code: formvalidator.isTextValid(Regexs.postalCode),
        street: formvalidator.isStreetValid,
        coordinates: formvalidator.isArrayValid(formvalidator.isDecimalNumberValid(), 2, 2),
        admin_name2: formvalidator.isTextValid(),
        place_name: formvalidator.isTextValid(),
    }),
}

const producerDeliveryAttributeValidators = {
    name: formvalidator.isTextValid(Regexs.all, 1, 50),
    freeFrom: formvalidator.condition({
        if: (value, form) => {
            return form['availableFrom'] > 0
        },
        then: formvalidator.condition({
            if: (value, form) => {
                return value <= 0
            },
            then: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: false }),
            else: formvalidator.isDecimalNumberValid({ min: (value, form) => form['availableFrom'], minEqFail: true })
        }),
        else: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: false })
    }),
    availableFrom: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: false }),
    modality: formvalidator.isObjectValid({
        personal: formvalidator.condition({
            if: formvalidator.expresionEquals(
                formvalidator.grabPartnerValue('external'),
                () => undefined
            ),
            then: formvalidator.isObjectValid({
                days: formvalidator.isArrayValid(
                    formvalidator.condition({
                        if: (value, form) => {
                            return typeof value['endTime'] !== 'undefined' && typeof value['endTime'] !== 'undefined' &&
                                (value['endTime'] < value['startTime'] || value['startTime'] > value['endTime'])
                        },
                        then: () => Errors.WRONG_VALUE,
                        else: formvalidator.isObjectValid({
                            day: formvalidator.isInArray([0, 1, 2, 3, 4, 5, 6])
                        })
                    })),
            }),
            else: () => Errors.NO_ERROR
        }),
        external: formvalidator.condition({
            if: formvalidator.expresionEquals(
                formvalidator.grabPartnerValue('personal'),
                () => undefined
            ),
            then: formvalidator.isObjectValid({
                time: formvalidator.isInArray([1, 2, 4, 7]),
            }),
            else: () => Errors.NO_ERROR
        }),
    }),
    price: formvalidator.condition({
        if: (value, form) => {
            return form['freeFrom'] > 0
        },
        then: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: true }),
        else: formvalidator.isDecimalNumberValid({ min: 0, minEqFail: false })
    }),
    suplements: formvalidator.isObjectValid({
        weight: formvalidator.isOptional(formvalidator.isObjectValid({
            interval: formvalidator.isInArray([0, 1, 2]),
            value: formvalidator.isDecimalNumberValid({ min: 0.01 }),
        }))
    }),
    places: formvalidator.isArrayValid(formvalidator.isObjectIdValid)
}

const becomeProducerAttributeValidators = {
    producerName: formvalidator.isTextValid(),
    phone: formvalidator.isTelephoneValid,
    website: formvalidator.isOptional(formvalidator.isWebsiteValid),
    message: formvalidator.isTextValid()

}

const producerAttributeValidators = {
    name: formvalidator.isNameValid,
    location: formvalidator.isObjectValid({
        postal_code: formvalidator.isTextValid(Regexs.postalCode),
        street: formvalidator.isStreetValid,
        coordinates: formvalidator.isArrayValid(formvalidator.isDecimalNumberValid(), 0, 2),
        admin_name2: formvalidator.isTextValid(),
        place_name: formvalidator.isTextValid(),
    }),
    translations: formvalidator.isTranslationValid({
        description: formvalidator.isTextValid(Regexs.all)
    }),
    images: formvalidator.isObjectValid({
        cover: formvalidator.isObjectIdValid,
        profile: formvalidator.isObjectIdValid,
        list: formvalidator.isArrayValid(formvalidator.isObjectIdValid, 1, 10)
    })
}

const transitOrderAttributeValidators = {
    external: formvalidator.condition({
        if: formvalidator.expresionEquals(
            formvalidator.grabPartnerValue('selectedIndex'),
            () => 0
        ),
        then: formvalidator.isObjectValid({
            sendingTime: formvalidator.isNumberValid(),
            companyName: formvalidator.condition({
                if: formvalidator.expresionEquals(
                    formvalidator.grabPartnerValue('company'),
                    () => 'other'
                ),
                then: formvalidator.isTextValid(),
                else: () => Errors.NO_ERROR
            }),
            company: formvalidator.isTextValid(),
            trackingId: formvalidator.isOptional(formvalidator.isTextValid()),
        }),
        else: () => Errors.NO_ERROR
    }),
    personal: formvalidator.condition({
        if: formvalidator.expresionEquals(
            formvalidator.grabPartnerValue('selectedIndex'),
            () => 1
        ),
        then: formvalidator.isObjectValid({
            message: formvalidator.isOptional(formvalidator.isTextValid()),
            date: formvalidator.isDateValid(),
            timeRange: formvalidator.isObjectValid({
                start: formvalidator.isDateValid(),
                end: formvalidator.isDateValid()
            }),
            phone: formvalidator.isTelephoneValid
        }),
        else: () => Errors.NO_ERROR
    }),
    selectedIndex: formvalidator.isNumberValid(0, false, 2, true),
}

// Finish setting direction to follow cloud schema
const directionAttributeValidators = {
    location: formvalidator.isObjectValid({
        place_name: formvalidator.isStreetValid,
        coordinates: formvalidator.isArrayValid(formvalidator.isDecimalNumberValid(), 2, 2),
        type: formvalidator.isTextValid(),
        admin_name2: formvalidator.isTextValid(),
        street: formvalidator.condition({
            if: (value, form) => {
                return typeof value.flat !== 'undefined' && value.flat != null
            },
            then: formvalidator.isObjectValid({
                name: formvalidator.isStreetValid,
                flat: formvalidator.isStreetValid
            }),
            else: formvalidator.isObjectValid({
                name: formvalidator.isStreetValid
            })
        }),
        postal_code: formvalidator.isRegexValid(Regexs.postalCode),
    }),
    name: formvalidator.isNameValid,
    phone: formvalidator.isTelephoneValid,
    note: formvalidator.isOptional(formvalidator.isTextValid()),
}

const registerAttributeErrorsStrings = {
    password: {
        [Errors.NO_MATCH]: '$common.formValidator.passwordIncorrectRequirement',
    },
    confirmationPassword: {
        [Errors.NO_MATCH]: '$common.formValidator.passwordNoMatch',
    },
}

const loginAttributeErrorsStrings = {
    email: {
        [Errors.NO_MATCH]: '$common.formValidator.passwordIncorrectRequirement',
    },
    password: {
        [Errors.NO_MATCH]: '$common.formValidator.passwordIncorrectRequirement',
    },
}

const producerDeliveryAttributeErrorsStrings = {
    modality: {
        [Errors.EMPTY]: '$common.formValidator.modalityUnSelected',
        personal: {
            days: {
                [Errors.EMPTY]: '$common.formValidator.modalityUnSelected',
                [Errors.MIN_ARRAY_LENGTH]: '$common.formValidator.modalityUnSelected',
                [Errors.WRONG_VALUE]: '$common.formValidator.startAndEndTimeWrongOrder',
            }
        },
        external: {
            time: {
                [Errors.EMPTY]: '$common.formValidator.modalityUnSelected',
            }
        }
    },
    zones: {
        [Errors.EMPTY]: '$common.formValidator.sellingZones',
    },
    freeFrom: {
        [Errors.VALUE_MIN_LIMIT]: '$zones.details.errors.freeFromBiggerThanMinOrder'
    },
    days: {
        [Errors.NO_MATCH]: '$common.formValidator.deliveryDaysWrongValue',
    },
    places: {
        [Errors.MIN_ARRAY_LENGTH]: '$zones.details.zoneNotSelected',
    }
}

const fiscalUserAttributeErrorsStrings = {
    birthday: {
        [Errors.WRONG_VALUE]: '$common.formValidator.birthdayMinor',
    }
}

const fiscalUserAttributeValidators = {
    name: formvalidator.isNameValid,
    surname: formvalidator.isNameValid,
    birthday: formvalidator.isDateValid(18),
    nationality: formvalidator.isTextValid(),
    countryOfResidence: formvalidator.isTextValid(),
}

const loginAttributeValidators = {
    email: formvalidator.isEmailValid,
    password: formvalidator.isPasswordValid
}

const forgotPasswordFormValidations = {
    email: formvalidator.isEmailValid
}

const resetPasswordAttributeValidators = {
    password: formvalidator.isPasswordValid,
    confirmationPassword: formvalidator.condition({
        if: formvalidator.expresionEquals(
            formvalidator.grabPartnerValue('password'),
            formvalidator.grabPartnerValue('confirmationPassword')
        ),
        then: () => Errors.NO_ERROR,
        else: () => Errors.NO_MATCH
    }),
}

const registerAttributeValidators = {
    name: formvalidator.isNameValid,
    email: formvalidator.isEmailValid,
    password: formvalidator.isPasswordValid,
    confirmationPassword: formvalidator.condition({
        if: formvalidator.expresionEquals(
            formvalidator.grabPartnerValue('password'),
            formvalidator.grabPartnerValue('confirmationPassword')
        ),
        then: () => Errors.NO_ERROR,
        else: () => Errors.NO_MATCH
    }),
}

const companyMongodbValidators = {
    Name: formvalidator.isTextValid(),
    CompanyNumber: formvalidator.isNIFValid,
    Email: formvalidator.isEmailValid,
    payment: formvalidator.isObjectValid({
        wallet: formvalidator.isTextValid(),
        user: formvalidator.isTextValid(),
        bankaccount: formvalidator.isTextValid()
    })
}

const bankAccountValidators = {
    IBAN: formvalidator.isIbanValid,
    OwnerName: formvalidator.isTextValid(),
    OwnerAddress: formvalidator.isObjectValid({
        AddressLine1: formvalidator.isStreetValid,
        City: formvalidator.isStreetValid,
        Region: formvalidator.isStreetValid,
        PostalCode: formvalidator.isRegexValid(Regexs.postalCode),
        Country: formvalidator.isTextValid()
    })
}

const companyMangopayValidators = {
    HeadquartersAddress: formvalidator.isObjectValid({
        AddressLine1: formvalidator.isStreetValid,
        City: formvalidator.isStreetValid,
        Region: formvalidator.isStreetValid,
        PostalCode: formvalidator.isRegexValid(Regexs.postalCode),
        Country: formvalidator.isTextValid()
    })
}

const companyGlobalValidators = {
    Name: formvalidator.isTextValid(),
    CompanyNumber: formvalidator.isNIFValid,
    LegalPersonType: formvalidator.isTextValid(),
    Email: formvalidator.isEmailValid,
    PhoneNumber: formvalidator.isTelephoneValid,
    HeadquartersAddress: formvalidator.isObjectValid({
        AddressLine1: formvalidator.isStreetValid,
        City: formvalidator.isStreetValid,
        Region: formvalidator.isStreetValid,
        PostalCode: formvalidator.isRegexValid(Regexs.postalCode),
        Country: formvalidator.isTextValid()
    })
}

const companyLegalRepresentativeValidators = {
    LegalRepresentativeFirstName: formvalidator.isNameValid,
    LegalRepresentativeLastName: formvalidator.isNameValid,
    LegalRepresentativeEmail: formvalidator.isEmailValid,
    LegalRepresentativeBirthday: formvalidator.isDateValid(18),
    LegalRepresentativeNationality: formvalidator.isTextValid(),
    LegalRepresentativeCountryOfResidence: formvalidator.isTextValid(),
    LegalRepresentativeAddress: formvalidator.isObjectValid({
        AddressLine1: formvalidator.isStreetValid,
        City: formvalidator.isStreetValid,
        Region: formvalidator.isStreetValid,
        PostalCode: formvalidator.isRegexValid(Regexs.postalCode),
        Country: formvalidator.isTextValid()
    }),

}

const companyAttributeValidators = {
    HeadquartersAddress: formvalidator.isObjectValid({
        AddressLine1: formvalidator.isStreetValid,
        City: formvalidator.isStreetValid,
        Region: formvalidator.isStreetValid,
        PostalCode: formvalidator.isRegexValid(Regexs.postalCode),
        Country: formvalidator.isTextValid()
    }),
    LegalRepresentativeAddress: formvalidator.isObjectValid({
        AddressLine1: formvalidator.isStreetValid,
        City: formvalidator.isStreetValid,
        Region: formvalidator.isStreetValid,
        PostalCode: formvalidator.isRegexValid(Regexs.postalCode),
        Country: formvalidator.isTextValid()
    }),
    Name: formvalidator.isTextValid(),
    CompanyNumber: formvalidator.isNIFValid,
    PhoneNumber: formvalidator.isTelephoneValid,
    LegalPersonType: formvalidator.isTextValid(),
    LegalRepresentativeFirstName: formvalidator.isNameValid,
    LegalRepresentativeLastName: formvalidator.isNameValid,
    LegalRepresentativeEmail: formvalidator.isEmailValid,
    LegalRepresentativeBirthday: formvalidator.isDateValid(18),
    LegalRepresentativeNationality: formvalidator.isTextValid(),
    LegalRepresentativeCountryOfResidence: formvalidator.isTextValid(),
    Email: formvalidator.isEmailValid,
    payment: formvalidator.isObjectValid(bankAccountValidators)
}

const forgotPasswordFormValidation = formvalidator.performValidation(forgotPasswordFormValidations);
const loginFormValidation = formvalidator.performValidation(loginAttributeValidators, loginAttributeErrorsStrings);
const registerFormValidation = formvalidator.performValidation(registerAttributeValidators, registerAttributeErrorsStrings);
const directionFormValidation = formvalidator.performValidation(directionAttributeValidators);
const directionReducedFormValidation = formvalidator.performReducedValidation(directionAttributeValidators);
const transitOrderFormValidation = formvalidator.performValidation(transitOrderAttributeValidators);
const fiscalUserFormValidator = formvalidator.performValidation(fiscalUserAttributeValidators, fiscalUserAttributeErrorsStrings);
const resetPasswordFormValidation = formvalidator.performValidation(resetPasswordAttributeValidators, registerAttributeErrorsStrings);

const producerFormValidationErrors = formvalidator.performValidation(producerAttributeValidators, producerAttributeErrorsStrings);
const producerFormReducedValidationErrors = formvalidator.performReducedValidation(producerAttributeValidators, producerAttributeErrorsStrings);
const producerDeliveryFormValidationErrors = formvalidator.performValidation(producerDeliveryAttributeValidators, producerDeliveryAttributeErrorsStrings);
const producerPickupFormValidationErrors = formvalidator.performValidation(producerPickupAttributeValidators);

const becomeProducerValidationErrors = formvalidator.performValidation(becomeProducerAttributeValidators);

const productFormValidationErrors = formvalidator.performValidation(productAttributeValidators, productAttributeErrorsStrings);
const productFormReducedValidationErrors = formvalidator.performReducedValidation(productAttributeValidators, productAttributeErrorsStrings);

const kycFormValidationErrors = formvalidator.performValidation(kycAttributeValidators, kycAttributeErrorsStrings);
const kycFormReducedValidationErrors = formvalidator.performReducedValidation(kycAttributeValidators, kycAttributeErrorsStrings);

const companyMongodbValidation = formvalidator.performValidation(companyMongodbValidators);
const companyMangopayValidation = formvalidator.performValidation(companyMangopayValidators);

const companyFormValidation = formvalidator.performValidation(companyAttributeValidators);
const companyFormReducedValidation = formvalidator.performReducedValidation(companyAttributeValidators);

const companyGlobalValidation = formvalidator.performValidation(companyGlobalValidators);
const companyLegalRepresentativeValidation = formvalidator.performValidation(companyLegalRepresentativeValidators);

const bankAccountValidation = formvalidator.performValidation(bankAccountValidators);

const isFormValid = formvalidator.formIsValid

export {
    forgotPasswordFormValidation,
    kycFormValidationErrors,
    kycFormReducedValidationErrors,
    loginFormValidation,
    fiscalUserFormValidator,
    transitOrderFormValidation,
    productFormValidationErrors,
    productFormReducedValidationErrors,
    productFormValidationDependencies,
    producerPickupFormValidationErrors,
    becomeProducerValidationErrors,
    producerFormValidationErrors,
    producerFormReducedValidationErrors,
    producerDeliveryFormValidationErrors,
    directionFormValidation,
    directionReducedFormValidation,
    registerFormValidation,
    resetPasswordFormValidation,
    bankAccountValidation,
    companyMongodbValidation,
    companyMangopayValidation,
    companyFormValidation,
    companyFormReducedValidation,
    companyGlobalValidation,
    companyLegalRepresentativeValidation,
    companyGlobalValidators,
    companyLegalRepresentativeValidators,
    isFormValid
}