const baseErrors = require('./base').RESPONSE_CODES;

const RESPONSE_CODES = Object.freeze({
    ...baseErrors,
    UNABLE_CREATE_USER: 4,
    UNABLE_CREATE_WALLET: 5,
    PRODUCER_NOT_FOUND: 6,
    UNABLE_CREATE_DOCUMENT: 7,
    COMPANY_NOT_FOUND: 8,
    MANGOPAY_USER_NOT_FOUND: 9,
    UBO_FORM_NOT_VALID: 10,
    MISSING_KYC_DOCUMENTS: 11,
    MISSING_IDENTITY_DOCUMENT: 12,
    MISSING_REGISTRATION_DOCUMENT: 13,
    MISSING_ARTICLES_DOCUMENT: 14,
    UBO_FORM_NOT_STORED_MANGOPAY: 15,
    KYC_FORM_NOT_STORED: 16,
    UBO_FORM_MAX_UBOS: 17,
    KYC_FORM_NOT_PERMITED: 18,
    UNABLE_SUBMIT_DOCUMENT: 19,
    KYC_FORM_ALREADY_SUBMITED: 20,
    UNABLE_STORE_USER: 21,
    NATURAL_USER_ALREADY_CREATED: 22
})

const COMPANY_TYPES = Object.freeze({
    BUSINESS: "BUSINESS",
    ORGANIZATION: "ORGANIZATION",
    SOLETRADER: "SOLETRADER" //Autonom
})

const DOCUMENT_TYPES = Object.freeze({
    "IDENTITY_PROOF": "IDENTITY_PROOF",
    "REGISTRATION_PROOF": "REGISTRATION_PROOF",
    "ARTICLES_OF_ASSOCIATION": "ARTICLES_OF_ASSOCIATION",
    "SHAREHOLDER_DECLARATION": "SHAREHOLDER_DECLARATION",
    "ADDRESS_PROOF": "ADDRESS_PROOF"
})

const MANGOPAY_RESPONSES = Object.freeze({
    'unknownError': '$common.errors.unknown',
    'onlyCreated': '$common.codes.mangopay.onlyCreated',
    'SUCCEEDED': '$common.codes.mangopay.succeeded',
    'CREATED': '$common.codes.mangopay.created',
    //Operation failed
    '001999': '$common.codes.mangopay.001999',
    '001001': '$common.codes.mangopay.001001',
    '001002': '$common.codes.mangopay.001002',
    '001011': '$common.codes.mangopay.001011',
    '001012': '$common.codes.mangopay.001012',
    '001013': '$common.codes.mangopay.001013',
    '001014': '$common.codes.mangopay.001014',
    
    // KYC transaction errors
    '002999': '$common.codes.mangopay.002999',
    '002998': '$common.codes.mangopay.002998',

    // Refund transaction errors
    '001401': '$common.codes.mangopay.001401',
    '005403': '$common.codes.mangopay.005403',
    '005404': '$common.codes.mangopay.005404',
    '005405': '$common.codes.mangopay.005405',
    '005407': '$common.codes.mangopay.005407',
    '001403': '$common.codes.mangopay.001403',

    // Card input errors
    '105101': '$common.codes.mangopay.105101',
    '105102': '$common.codes.mangopay.105102',
    '105103': '$common.codes.mangopay.105103',
    '105104': '$common.codes.mangopay.105104',

    // Secure mode / 3DSecure errors
    '101399': '$common.codes.mangopay.101399',
    '101304': '$common.codes.mangopay.101304',
    '101303': '$common.codes.mangopay.101303',
    '101302': '$common.codes.mangopay.101302',
    '101301': '$common.codes.mangopay.101301',

    //Technical errors
    '009103': '$common.codes.mangopay.009103',
    '009199': '$common.codes.mangopay.009199',
    '009499': '$common.codes.mangopay.009499',
    '009999': '$common.codes.mangopay.009999',
    '009101': '$common.codes.mangopay.009101',
    '101202': '$common.codes.mangopay.101202',

    //Transaction Refused
    '101101': '$common.codes.mangopay.101101',
    '101102': '$common.codes.mangopay.101102',
    '101103': '$common.codes.mangopay.101103',
    '101104': '$common.codes.mangopay.101104',
    '101105': '$common.codes.mangopay.101105',
    '101106': '$common.codes.mangopay.101106',
    '101108': '$common.codes.mangopay.101108',
    '101109': '$common.codes.mangopay.101109',
    '101110': '$common.codes.mangopay.101110',
    '101410': '$common.codes.mangopay.101410',
    '101111': '$common.codes.mangopay.101111',
    '101112': '$common.codes.mangopay.101112',
    '101113': '$common.codes.mangopay.101113',
    '101115': '$common.codes.mangopay.101115',
    '101116': '$common.codes.mangopay.101116',
    '101118': '$common.codes.mangopay.101118',
    '002996': '$common.codes.mangopay.002996',
    '101199': '$common.codes.mangopay.101199',
    '205001': '$common.codes.mangopay.205001',

    //Transaction fraud issue
    '008999': '$common.codes.mangopay.008999',
    '008001': '$common.codes.mangopay.008001',
    '008002': '$common.codes.mangopay.008002',
    '008003': '$common.codes.mangopay.008003',
    '008004': '$common.codes.mangopay.008004',
    '008005': '$common.codes.mangopay.008005',
    '008006': '$common.codes.mangopay.008006',
    '008007': '$common.codes.mangopay.008007',
    '008500': '$common.codes.mangopay.008500',
    '008504': '$common.codes.mangopay.008504',
    '008505': '$common.codes.mangopay.008505',
    '008506': '$common.codes.mangopay.008506',
    '008507': '$common.codes.mangopay.008507',
    '008508': '$common.codes.mangopay.008508',
    '008509': '$common.codes.mangopay.008509',
    '008510': '$common.codes.mangopay.008510',
    '008511': '$common.codes.mangopay.008511',
    '008512': '$common.codes.mangopay.008512',
    '008513': '$common.codes.mangopay.008513',
    '008515': '$common.codes.mangopay.008515',
    '008514': '$common.codes.mangopay.008514',
    '008600': '$common.codes.mangopay.008600',
    '008700': '$common.codes.mangopay.008700',
})

const RESPONSE_MESSAGES = Object.freeze({
    [RESPONSE_CODES.OK]: '$common.codes.mangopay.ok',
    [RESPONSE_CODES.ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.UNKNOWN_ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.UNABLE_CREATE_USER]: '$common.codes.mangopay.unableCreateUser',
    [RESPONSE_CODES.UNABLE_CREATE_WALLET]: '$common.codes.mangopay.unableCreateWallet',
    [RESPONSE_CODES.PRODUCER_NOT_FOUND]: '$common.codes.mangopay.producerNotFound',
    [RESPONSE_CODES.UNABLE_CREATE_DOCUMENT]: '$common.codes.mangopay.unableCreateDocument',
    [RESPONSE_CODES.COMPANY_NOT_FOUND]: '$common.errors.unknown',
    [RESPONSE_CODES.MANGOPAY_USER_NOT_FOUND]: '$common.errors.unknown',
    [RESPONSE_CODES.UBO_FORM_NOT_VALID]: "$common.codes.mangopay.uboFormNotValid",
    [RESPONSE_CODES.MISSING_KYC_DOCUMENTS]: "$common.codes.mangopay.missingKycDocuments",
    [RESPONSE_CODES.MISSING_IDENTITY_DOCUMENT]: "$common.codes.mangopay.missingIdentityDocument",
    [RESPONSE_CODES.MISSING_REGISTRATION_DOCUMENT]: "$common.codes.mangopay.missingRegistryDocument",
    [RESPONSE_CODES.MISSING_ARTICLES_DOCUMENT]: "$common.codes.mangopay.missingArticles",
    [RESPONSE_CODES.UBO_FORM_NOT_STORED_MANGOPAY]: "$common.errors.unknown",
    [RESPONSE_CODES.KYC_FORM_NOT_STORED]: "$common.errors.formNotStored",
    [RESPONSE_CODES.UBO_FORM_MAX_UBOS]: "$common.codes.mangopay.maxUbosStored",
    [RESPONSE_CODES.KYC_FORM_ALREADY_SUBMITED]: "$common.codes.mangopay.formAlreadySubmited",
    [RESPONSE_CODES.UNABLE_SUBMIT_DOCUMENT]: "$common.codes.mangopay.unableSubmitDocument",
    [RESPONSE_CODES.UNABLE_STORE_USER]: "$common.errors.unknown",
    [RESPONSE_CODES.NATURAL_USER_ALREADY_CREATED]: "$common.codes.mangopay.alreadyCreated",
})

export {
    RESPONSE_CODES,
    RESPONSE_MESSAGES,
    MANGOPAY_RESPONSES,
    DOCUMENT_TYPES,
    COMPANY_TYPES
}