
export const TYPES = Object.freeze({
    // Client
    ORDER_NEW: 0,
    ORDER_CONFIRMED: 1,
    ORDER_TRANSIT: 2,
    ORDER_DELIVERED: 3,
    ORDER_CANCELED: 4,
    ORDER_DISPUTE: 5,
    ORDER_MESSAGE: 6,
    ORDER_DISPUTE_MARKED_AS_SOLVED: 7,
    ORDER_REOPEN_DISPUTE: 8,
    ORDER_READY_TO_PICKUP: 9,
    ORDER_PICKUP_DONE: 10,
    ORDER_TRANSIT_PERSONAL: 11,

    // Pro Notifications
    ORDER_NEW_PRO: 500,
    ORDER_MESSAGE_PRO: 501,
    ORDER_CANCELLED_PRO: 502,
    ACCEPTED_PRODUCER: 503,
    PRODUCER_VALIDATED: 504,
    KYC_FORM_VALID: 505,
    PRODUCT_WITHOUT_STOCK: 506,
    PRODUCT_LOW_STOCK: 507,
    ORDER_SINGLE_REMAINING: 508,
    ORDER_MULTIPLE_REMAINING: 509,
    ORDER_DISPUTE_PRO: 510,

    // User
    RESET_PASSWORD: 1000,
})