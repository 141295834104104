const baseErrors = require('./base').RESPONSE_CODES;

const RESPONSE_CODES = Object.freeze({
    ...baseErrors,
    UNABLE_CREATE_USER:                         4,
    UNABLE_CREATE_WALLET:                       5,
    PRODUCER_NOT_FOUND:                         6,
    NIF_EXISTS:                                 7,
    UNABLE_INSERT_COMPANY:                      8,
    UNABLE_CREATE_PRODUCER:                     9,
    USER_NOT_EXISTS:                            10,
    UPDATE_NOT_ALLOWED:                         11,
    FORM_NOT_VALID:                             12,
    UNABLE_PERFORM_UPDATE:                      13,
    FORM_ERRORS:                                14,
    UNABLE_CREATE_LEGAL:                        16,
    UNABLE_PERFORM_UPDATE:                      17,
    COMPANY_NOT_FOUND:                          18,

    NEXTSTEP_SUBMIT_INVALID_PRODUCER_STATUS:    19,
    NEXTSTEP_SUBMIT_COMPANY_PENDING:            20,
    NEXTSTEP_SUBMIT_KYC_PENDING:                21,
    NEXTSTEP_SUBMIT_PROFILE_PENDING:            22,
})

const COMPANY_TYPES = Object.freeze({
    BUSINESS: "BUSINESS",
    ORGANIZATION: "ORGANIZATION",
    SOLETRADER: "SOLETRADER" //Autonom
})

const STATUS = Object.freeze({
    ENABLED: 0,
    DISABLED: 1,
    VALIDATED: 2,
    WAITING_OUR_VALIDATION: 3,
    WAITING_REQUIRED_INFORMATION: 4
})

const RESPONSE_MESSAGES = Object.freeze({
    [RESPONSE_CODES.OK]: '$common.codes.producer.ok',
    [RESPONSE_CODES.ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.UNKNOWN_ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.UNABLE_CREATE_USER]: '$common.codes.mangopay.unableCreateUser',
    [RESPONSE_CODES.UNABLE_CREATE_WALLET]: '$common.codes.mangopay.unableCreateWallet',
    [RESPONSE_CODES.PRODUCER_NOT_FOUND]: '$common.codes.mangopay.producerNotFound',
    [RESPONSE_CODES.NIF_EXISTS]: '$common.codes.producer.nifExists',
    [RESPONSE_CODES.UNABLE_INSERT_COMPANY]: '$common.codes.producer.unableInsertCompany',
    [RESPONSE_CODES.UNABLE_CREATE_PRODUCER]: '$common.codes.producer.unableCreateProducer',
    [RESPONSE_CODES.USER_NOT_EXISTS]: '$common.codes.producer.userNotExists',
    [RESPONSE_CODES.FORM_ERRORS]: '$common.codes.producer.formErrors',
    [RESPONSE_CODES.UNABLE_PERFORM_UPDATE]: '$common.codes.producer.unablePerformUpdate',
    [RESPONSE_CODES.FORM_NOT_VALID]: '$common.codes.producer.formErrors',
})

export {
    RESPONSE_CODES,
    RESPONSE_MESSAGES,
    COMPANY_TYPES,
    STATUS
}