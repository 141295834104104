
export const Regexs = Object.freeze({
    all: /.*/,
    note: /^[a-zA-Z \-.ªº,0-9\/\n!?+]*$/,
    name: /^[a-zA-Z' ]+$/,
    street: /^[a-zA-Z \-.ª'º°,0-9()\/\n]+$/,
    postalCode: /^\d{5}$/,
    password: {
        containsLetter: /^.*[A-Za-z]+.*$/,
        containsNumber: /^.*\d+.*$/,
        containsSimbols: /^.*(\W|_)+.*$/,
        minimumLength: /^.{6,}$/,
    },
    nif: /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/,
    text: /^.+$/i,
})

export const SUPORTED_LOCALES = Object.freeze({
    'es': true,
    'ca': true,
    'en': true,
    'eu': true,
    'gl': true,
    'oc': true,
    'ast': true,
    'an': true
})

const PasswordErrors = Object.freeze({
    WRONG_MUST_CONTAIN_LETTERS: 100,
    WRONG_MUST_CONTAIN_NUMBER: 101,
    WRONG_MUST_CONTAIN_SYMBOLS: 102,
    WRONG_MIN_LENGTH: 103
})

const PasswordErrorsStrings = Object.freeze({
    [PasswordErrors.WRONG_MUST_CONTAIN_LETTERS]: '$common.formValidator.passwordMustContainLetters',
    [PasswordErrors.WRONG_MUST_CONTAIN_NUMBER]: '$common.formValidator.passwordMustContainNumbers',
    [PasswordErrors.WRONG_MUST_CONTAIN_SYMBOLS]: '$common.formValidator.passwordMustContainSymbols',
    [PasswordErrors.WRONG_MIN_LENGTH]: '$common.formValidator.passwordMinLength',
})

export const Errors = Object.freeze({
    NO_ERROR: 0,
    EMPTY: 1,
    MIN_ARRAY_LENGTH: 2,
    MAX_ARRAY_LENGTH: 3,
    WRONG_VALUE: 4,
    VALUE_MIN_LIMIT: 5,
    VALUE_MAX_LIMIT: 6,
    NO_MATCH: 7,
    ...PasswordErrors
})


const FreeDeliveries = Object.freeze({
    ALWAYS_FREE: 0,
    FROM_PRICE: 1,
    NEVER_FREE: 2,
    ALWAYS_FREE_STR: 'always free',
    NEVER_FREE_STR: 'never free'
})

const ErrorsStrings = Object.freeze({
    [Errors.NO_ERROR]: undefined,
    [Errors.EMPTY]: '$common.formValidator.emptyField',
    [Errors.WRONG_VALUE]: '$common.formValidator.invalidValue',
    [Errors.NO_MATCH]: '$common.formValidator.noMatch',
    [Errors.MIN_ARRAY_LENGTH]: '$common.formValidator.invalidArrayMin',
    [Errors.MAX_ARRAY_LENGTH]: '$common.formValidator.invalidArrayMax',
    [Errors.VALUE_MIN_LIMIT]: '$common.formValidator.invalidValueMin',
    [Errors.VALUE_MAX_LIMIT]: '$common.formValidator.invalidValueMax',
    ...PasswordErrorsStrings
})

export var isInArray = (possibleValues) => (value) => {
    if (typeof value === 'undefined' || value == null)
        return Errors.EMPTY

    return possibleValues.indexOf(value) != -1 ? Errors.NO_ERROR : Errors.NO_MATCH
}

export var alwaysValid = (value) => {
    return Errors.NO_ERROR;
}

export function isLocaleSuported(value) {
    return SUPORTED_LOCALES[value] ? Errors.NO_ERROR : Errors.NO_MATCH
}

export const isAMatch = (pattern) => (value) => {

    if (typeof value === 'undefined')
        return Errors.EMPTY

    return pattern.test(value) ? Errors.NO_ERROR : Errors.NO_MATCH

}

export const isRegexValid = (regex) => (value) => {
    if (value === null || typeof value === 'undefined') {
        value = ''
    }
    return regex.test(value) ? Errors.NO_ERROR : Errors.WRONG_VALUE
}

/**
 * 
 * @param {} value 
 */
export const isTextValid = (regex = Regexs.all, minLength = 1, maxLenght = Number.MAX_SAFE_INTEGER) => (value) => {
    if (typeof value !== 'undefined' && typeof value == 'string') {
        value = value.trim()
    }
    if (typeof value !== 'string' || value == null || value.length < minLength || value.length >= maxLenght)
        return Errors.EMPTY

    let filtValue = removeDiacritics(value)
    if (regex && !regex.test(filtValue)) {
        return Errors.WRONG_VALUE
    }

    return Errors.NO_ERROR
}

export const isArrayValid = (valueValidator, minLength = 1, maxLenght = Number.MAX_SAFE_INTEGER) => (array, partners) => {
    let result = []
    if (typeof array === 'undefined' || array === null)
        return [Errors.EMPTY]

    if (array.length < minLength)
        return [Errors.MIN_ARRAY_LENGTH]

    if (array.length > maxLenght)
        return [Errors.MAX_ARRAY_LENGTH]

    array.forEach(element => {
        result.push(valueValidator(element, partners))
    });
    return result

}

// If you got lost reading this name, ask Xavier Roma about it
// Previous name: unfoldOperationForwardAndFoldOperation
export const unFoldCheckFold = (unfold, validator, fold) => (value, partners) => {
    return fold(validator(unfold(value, partners)), value, partners)
}

export const isObjectValid = (objectValidator) => (object) => {
    let objectResult = {}

    if (typeof object === 'undefined' || object === null)
        return Errors.EMPTY

    for (let field in objectValidator) {
        if (field in object) {
            objectResult[field] = objectValidator[field](object[field], object)
        } else {
            objectResult[field] = objectValidator[field](undefined, object)
        }
    }

    return objectResult
}

export const isNumberValid = ({ min, minEqFail = false, max, maxEqFail = false } = { min: undefined, minEqFail: false, max: undefined, maxEqFail: false }) => (value, partners) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY
    if (typeof value === 'string') value = parseInt(value)

    if (isNaN(parseFloat(value)) || !isFinite(parseFloat(value))) {
        return Errors.WRONG_VALUE
    } else {

        let compareValue

        if (typeof min !== 'undefined' && min != null) {
            compareValue = typeof min !== 'function' ? min : min(value, partners)

            if (typeof compareValue === 'string') compareValue = parseFloat(compareValue.replace(",", "."))

            if (!minEqFail && value < compareValue || minEqFail && value <= compareValue) {
                return Errors.VALUE_MIN_LIMIT
            }
        }
        if (typeof max !== 'undefined' && max != null) {
            compareValue = typeof max !== 'function' ? max : max(value, partners)

            if (typeof compareValue === 'string') compareValue = parseFloat(compareValue.replace(",", "."))

            if (!maxEqFail && value > compareValue || maxEqFail && value >= compareValue) {
                return Errors.VALUE_MAX_LIMIT
            }
        }

        return Errors.NO_ERROR

    }
}

export const isDecimalNumberValid = ({ min, minEqFail = false, max, maxEqFail = false } = { min: undefined, minEqFail: false, max: undefined, maxEqFail: false }) => (value, partners) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY
    if (typeof value === 'string') value = parseFloat(value.replace(",", "."))

    if (isNaN(parseFloat(value)) || !isFinite(parseFloat(value))) {
        return Errors.WRONG_VALUE
    } else {

        let compareValue

        if (typeof min !== 'undefined' && min != null) {
            compareValue = typeof min !== 'function' ? min : min(value, partners)

            if (typeof compareValue === 'string') compareValue = parseFloat(compareValue.replace(",", "."))

            if (!minEqFail && value < compareValue || minEqFail && value <= compareValue) {
                return Errors.VALUE_MIN_LIMIT
            }
        }
        if (typeof max !== 'undefined' && max != null) {
            compareValue = typeof max !== 'function' ? max : max(value, partners)
            if (typeof compareValue === 'string') compareValue = parseFloat(compareValue.replace(",", "."))

            if ((!maxEqFail && value > compareValue) || (maxEqFail && value >= compareValue)) {
                return Errors.VALUE_MAX_LIMIT
            }
        }

        return Errors.NO_ERROR

    }

}

export const isTranslationValid = (translationObject) => (value) => {
    return unFoldCheckFold((translations) => { // This can be done with Object.keys, but Otto does not support it yet
        let arr = []
        for (let key in translations) {
            if (key === '__typename') continue;
            arr.push(translations[key])
        }
        return arr;
    },
        isArrayValid(
            isObjectValid(translationObject)
        ),
        (result, translations) => {
            let realResult = {}
            let i = 0
            for (let key in translations) {
                if (key === '__typename') continue;
                realResult[key] = result[i++]
            }
            return realResult
        })(value)
}

export function isTelephoneValid(value) {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    let phone = value
    if (typeof phone.countryCode !== 'undefined' && typeof phone.number !== 'undefined') {
        phone = `${phone.countryCode}${phone.number}`
    } else if (typeof phone.number !== 'undefined') {
        phone = phone.number
    }

    if (typeof phone === 'string') {
        phone = phone.trim()
        phone = phone.replace(/\s+/g, '')
    }

    try {
        const phoneValidator = require('libphonenumber-js/mobile')
        try {
            const phoneNumber = phoneValidator.parsePhoneNumber(phone)
            if (!phoneNumber.isPossible()) {
                return Errors.WRONG_VALUE
            }
        } catch (error) {
            if (error instanceof phoneValidator.ParseError) {
                return Errors.WRONG_VALUE
            }
            return Errors.WRONG_VALUE
        }
    } catch (err) {
        console.warn(err)
        if (value.countryCode && value.number) {
            if (value.countryCode.length > 4 ||
                value.countryCode.length < 2) {
                return Errors.WRONG_VALUE
            } else {
                if (value.countryCode === '+34') {
                    if (value.number.length !== 9) {
                        return Errors.WRONG_VALUE
                    }
                }
                return Errors.NO_ERROR
            }
        }
        return Errors.WRONG_VALUE
    }
    return Errors.NO_ERROR

}

export function isNIFValid(value) {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    try {
        const nifValidator = require('spain-id')
        if (!nifValidator.validateSpanishId(value)) {
            return Errors.WRONG_VALUE
        }
    } catch (err) {
        console.warn(err)
        return isRegexValid(Regexs.nif)(value)
    }
    return Errors.NO_ERROR

}

export const isOptional = (ifFilledValidator) => (value, partners) => {
    if (typeof value === 'undefined' || value === null || value.length == 0 || value == '') {
        return Errors.NO_ERROR
    } else if (typeof value === 'object') {

        if (Array.isArray(value)) {

        } else {
            for (let field in ifFilledValidator) {
                if (field in value) {
                    value[field] = isOptional(ifFilledValidator[field])(value[field], partners)
                }
            }
        }

    } else {
        return ifFilledValidator(value, partners)
    }
}


export const isBooleanValid = (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    return typeof value === 'boolean' ? Errors.NO_ERROR : Errors.WRONG_VALUE

}

export const isObjectIdValid = (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    if (typeof value === 'object') {
        return value.toString().length == 24 ? Errors.NO_ERROR : Errors.WRONG_VALUE
    }

    return value.length == 24 ? Errors.NO_ERROR : Errors.WRONG_VALUE
}

export const isNameValid = (value) => {
    if (typeof value === 'undefined' || value === null ||
        value.length === 0 ||
        (typeof value !== 'undefined' && !value.trim()))
        return Errors.EMPTY

    let noDiacritics = removeDiacritics(value)
    return Regexs.name.test(String(noDiacritics).toLowerCase()) ? Errors.NO_ERROR : Errors.WRONG_VALUE;
}

export const isPasswordValid = (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    //Check it contains letters
    return !Regexs.password.containsLetter.test(String(value).toLowerCase()) ? PasswordErrors.WRONG_MUST_CONTAIN_LETTERS :
        !Regexs.password.containsNumber.test(String(value).toLowerCase()) ? PasswordErrors.WRONG_MUST_CONTAIN_NUMBER :
            !Regexs.password.minimumLength.test(String(value).toLowerCase()) ? PasswordErrors.WRONG_MIN_LENGTH :
                Errors.NO_ERROR
}

export const isEmailValid = (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    const re = /\S+@\S+/; //Email validation should happen on email token not here
    return re.test(String(value).toLowerCase()) ? Errors.NO_ERROR : Errors.WRONG_VALUE;
}

export const isWebsiteValid = (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value) ? Errors.NO_ERROR : Errors.WRONG_VALUE;
}

export const isDateValid = (minAge) => (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    try {
        let birthDate = new Date(value)
        if (typeof minAge !== 'undefined') {
            let tempDate = new Date(birthDate.getFullYear() + minAge,
                birthDate.getMonth(), birthDate.getDate());
            if (tempDate > new Date()) {
                return Errors.WRONG_VALUE
            }
        }
        //less than 1906-08-16T20:26:40.000Z
        if (birthDate.getTime() < -2000000000000) {
            return Errors.WRONG_VALUE
        }
    } catch (error) {
        return Errors.WRONG_VALUE
    }

}

export const isStreetValid = (value) => {
    if (typeof value === 'undefined' || value === null || value.length == 0)
        return Errors.EMPTY

    let filtValue = removeDiacritics(value)
    return isRegexValid(Regexs.street)(filtValue)
}

function isValidIBANNumber(input) {
    var CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }

    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}

function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

export const isIbanValid = (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    if (isValidIBANNumber(value) === 1) {
        return Errors.NO_ERROR
    }
    return Errors.WRONG_VALUE
}

export const isSellingZonesValid = (value) => {

    if (!(isArrayValid(isObjectIdValid, 0) &&
        isArrayValid(isTextValid(), 0) &&
        isArrayValid(isTextValid(), 0) &&
        isArrayValid(isTextValid(), 0))) {
        return Errors.WRONG_VALUE
    }

    if (value.cities.length === 0 &&
        value.countries.length === 0 &&
        value.provinces.length === 0 &&
        value.regions.length === 0) {
        return Errors.EMPTY
    }

    return Errors.NO_ERROR
}


export const isNotEmpty = (value) => {
    if (typeof value === 'undefined' || value === null)
        return Errors.EMPTY

    return Errors.NO_ERROR
}

export const and = (...validators) => (value) => {

    let errCode = Errors.NO_ERROR

    for (let i in validators) {
        errCode = validators[i](value)
        if (typeof errCode === 'object') {
            if (!formIsValid(errCode))
                break
        }
        if (errCode != Errors.NO_ERROR)
            break
    }

    return errCode

}

export const or = (...validators) => (value) => {

    let errCode = Errors.NO_ERROR

    for (let i in validators) {
        errCode = validators[i](value)
        if (typeof errCode === 'object') {
            if (formIsValid(errCode))
                break
        }
        if (errCode == Errors.NO_ERROR)
            break
    }

    return errCode

}


export const condition = ({ if: validatorIf, then: validatorThen, else: validatorElse }) => (value, partners) => {
    if (validatorIf(value, partners))
        return validatorThen(value, partners)
    return validatorElse(value, partners)
}

export const expresionEquals = (a, b) => (value, partners) => {
    return a(value, partners) === b(value, partners)
}

export const AND = (a, b) => (value, partners) => {
    return a(value, partners) && b(value, partners)
}

export const OR = (a, b) => (value, partners) => {
    return a(value, partners) || b(value, partners)
}

export const greaterThan = (a) => (value, partners) => {
    if (typeof a === 'function') {
        return value > a()
    }
    return value > a
}

export const EVALUATE = (evalCallback = () => true) => (value, partners) => {
    return evalCallback(value, partners)
}

export const evaluate = (evalCallback = () => true, error = Errors.WRONG_VALUE) => (value, partners) => {
    return evalCallback(value, partners) ? Errors.NO_ERROR : error
}

export const grabPartnerValue = (partnerKey) => (value, partners) => {
    return partners[partnerKey]
}

export function validationErrors(objectValidator, form) {
    return Object.keys(objectValidator).reduce
        (
            (errors, validator) => {
                if (validator == '_id') return errors
                if (validator == '__typename') return errors
                errors[validator] = objectValidator[validator](form[validator], form)
                return errors
            },
            {}
        )
}

const removeUnintendedMatches = (errors, form) => {

    if (typeof form === 'object') {

        for (let field in errors) {
            if (!(field in form)) {
                errors[field] = Errors.NO_ERROR
            } else if (typeof form[field] === 'object') {

                if (!Array.isArray(form[field])) {
                    //is an object
                    removeUnintendedMatches(errors[field], form[field])
                } else {
                    //is an array
                    if (form[field].length == 0 && (Errors.EMPTY == errors[field][0] || Errors.MIN_ARRAY_LENGTH == errors[field][0])) {
                        continue
                    } else {
                        removeUnintendedMatches(errors[field], form[field])
                    }
                }

            }

        }

    }
}
export function reducedValidationErrors(objectValidator, form, log = false) {

    let errors = Object.keys(form).reduce
        (
            (errors, validator) => {
                if (validator == '_id') return errors
                if (validator == '__typename') return errors
                if (typeof objectValidator[validator] === 'undefined') return errors
                errors[validator] = objectValidator[validator](form[validator], form)

                return errors
            },
            {}
        )
    if (log) {
        console.log('errors', JSON.stringify(errors))
    }
    removeUnintendedMatches(errors, form)

    if (log) {
        console.log('after errors', JSON.stringify(errors))
    }
    return errors
}

/**
 * For not generic errors, this function will resolve those defined for specific fields
 * @param {*} field 
 * @param {*} ErrorOverride 
 */
export function getErrorString(error, field, errorOverride) {

    if (typeof errorOverride[field] !== 'undefined' && typeof errorOverride[field][error] !== 'undefined') {
        return errorOverride[field][error]
    }

    return ErrorsStrings[error]

}

export function errorsTranslator(errors, errorOverride = {}) {
    //If error is undefined do not show anything

    //Iterate the error object (provinent from validationErrors())

    for (let key in errors) {
        //There are 3 scenarios: object, array or plane error
        if (typeof errors[key] === 'object') {
            //check if the content is an array
            let keys = Object.keys(errors[key])

            if (keys.some(value => isNaN(value))) {
                //In case of object examinate for errors recursive
                errors[key] = errorsTranslator(errors[key], typeof errorOverride[key] === 'undefined' ? {} : errorOverride[key])
            } else {
                //This is an array, in order to avoid defining the same errors N times when overriding, we inset inside the array
                for (let i in errors[key]) {
                    if (typeof errors[key][i] === 'object') {
                        //Object, examinate for errors recursive
                        errors[key][i] = errorsTranslator(errors[key][i], typeof errorOverride[key] === 'undefined' ? {} : errorOverride[key])
                    } else {
                        //Plane error, just translate the error code into string
                        errors[key][i] = getErrorString(errors[key][i], key, errorOverride)
                    }
                }

            }
        } else {
            //Plane error, just translate the error code into string
            errors[key] = getErrorString(errors[key], key, errorOverride)
        }
    }

    return errors;

}

export function formIsValid(errors) {

    for (let err in errors) {
        if (typeof errors[err] == 'object') {
            if (!formIsValid(errors[err])) {
                return false;
            } else {
                continue;
            }
        }

        if (errors[err] !== Errors.NO_ERROR && typeof errors[err] !== 'undefined' && errors[err] !== null) {
            return false;
        }
    }

    return true;
}

export const performValidation = (validator, errorStringOverride = undefined) => (form) => {
    let errors = validationErrors(validator, form)
    let isValid = formIsValid(errors)

    errors = errorsTranslator(errors, errorStringOverride)

    return { formErrors: errors, isValid: isValid };
}

export const performReducedValidation = (validator, errorStringOverride = undefined) => (form, log = false) => {
    let errors = reducedValidationErrors(validator, form, log)
    if (log) {
        console.log('errors', JSON.stringify(errors))
    }
    let isValid = formIsValid(errors)
    if (log) {
        console.log('isValid', isValid)
    }
    errors = errorsTranslator(errors, errorStringOverride)
    return { formErrors: errors, isValid: isValid };
}

var defaultDiacriticsRemovalMap = [
    { 'base': 'A', 'letters': '\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F' },
    { 'base': 'AA', 'letters': '\uA732' },
    { 'base': 'AE', 'letters': '\u00C6\u01FC\u01E2' },
    { 'base': 'AO', 'letters': '\uA734' },
    { 'base': 'AU', 'letters': '\uA736' },
    { 'base': 'AV', 'letters': '\uA738\uA73A' },
    { 'base': 'AY', 'letters': '\uA73C' },
    { 'base': 'B', 'letters': '\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181' },
    { 'base': 'C', 'letters': '\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E' },
    { 'base': 'D', 'letters': '\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0' },
    { 'base': 'DZ', 'letters': '\u01F1\u01C4' },
    { 'base': 'Dz', 'letters': '\u01F2\u01C5' },
    { 'base': 'E', 'letters': '\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E' },
    { 'base': 'F', 'letters': '\u0046\u24BB\uFF26\u1E1E\u0191\uA77B' },
    { 'base': 'G', 'letters': '\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E' },
    { 'base': 'H', 'letters': '\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D' },
    { 'base': 'I', 'letters': '\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197' },
    { 'base': 'J', 'letters': '\u004A\u24BF\uFF2A\u0134\u0248' },
    { 'base': 'K', 'letters': '\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2' },
    { 'base': 'L', 'letters': '\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780' },
    { 'base': 'LJ', 'letters': '\u01C7' },
    { 'base': 'Lj', 'letters': '\u01C8' },
    { 'base': 'M', 'letters': '\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C' },
    { 'base': 'N', 'letters': '\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4' },
    { 'base': 'NJ', 'letters': '\u01CA' },
    { 'base': 'Nj', 'letters': '\u01CB' },
    { 'base': 'O', 'letters': '\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C' },
    { 'base': 'OI', 'letters': '\u01A2' },
    { 'base': 'OO', 'letters': '\uA74E' },
    { 'base': 'OU', 'letters': '\u0222' },
    { 'base': 'OE', 'letters': '\u008C\u0152' },
    { 'base': 'oe', 'letters': '\u009C\u0153' },
    { 'base': 'P', 'letters': '\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754' },
    { 'base': 'Q', 'letters': '\u0051\u24C6\uFF31\uA756\uA758\u024A' },
    { 'base': 'R', 'letters': '\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782' },
    { 'base': 'S', 'letters': '\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784' },
    { 'base': 'T', 'letters': '\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786' },
    { 'base': 'TZ', 'letters': '\uA728' },
    { 'base': 'U', 'letters': '\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244' },
    { 'base': 'V', 'letters': '\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245' },
    { 'base': 'VY', 'letters': '\uA760' },
    { 'base': 'W', 'letters': '\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72' },
    { 'base': 'X', 'letters': '\u0058\u24CD\uFF38\u1E8A\u1E8C' },
    { 'base': 'Y', 'letters': '\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE' },
    { 'base': 'Z', 'letters': '\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762' },
    { 'base': 'a', 'letters': '\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250' },
    { 'base': 'aa', 'letters': '\uA733' },
    { 'base': 'ae', 'letters': '\u00E6\u01FD\u01E3' },
    { 'base': 'ao', 'letters': '\uA735' },
    { 'base': 'au', 'letters': '\uA737' },
    { 'base': 'av', 'letters': '\uA739\uA73B' },
    { 'base': 'ay', 'letters': '\uA73D' },
    { 'base': 'b', 'letters': '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253' },
    { 'base': 'c', 'letters': '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184' },
    { 'base': 'd', 'letters': '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A' },
    { 'base': 'dz', 'letters': '\u01F3\u01C6' },
    { 'base': 'e', 'letters': '\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD' },
    { 'base': 'f', 'letters': '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C' },
    { 'base': 'g', 'letters': '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F' },
    { 'base': 'h', 'letters': '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265' },
    { 'base': 'hv', 'letters': '\u0195' },
    { 'base': 'i', 'letters': '\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131' },
    { 'base': 'j', 'letters': '\u006A\u24D9\uFF4A\u0135\u01F0\u0249' },
    { 'base': 'k', 'letters': '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3' },
    { 'base': 'l', 'letters': '\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747' },
    { 'base': 'lj', 'letters': '\u01C9' },
    { 'base': 'm', 'letters': '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F' },
    { 'base': 'n', 'letters': '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5' },
    { 'base': 'nj', 'letters': '\u01CC' },
    { 'base': 'o', 'letters': '\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275' },
    { 'base': 'oi', 'letters': '\u01A3' },
    { 'base': 'ou', 'letters': '\u0223' },
    { 'base': 'oo', 'letters': '\uA74F' },
    { 'base': 'p', 'letters': '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755' },
    { 'base': 'q', 'letters': '\u0071\u24E0\uFF51\u024B\uA757\uA759' },
    { 'base': 'r', 'letters': '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783' },
    { 'base': 's', 'letters': '\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B' },
    { 'base': 't', 'letters': '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787' },
    { 'base': 'tz', 'letters': '\uA729' },
    { 'base': 'u', 'letters': '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289' },
    { 'base': 'v', 'letters': '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C' },
    { 'base': 'vy', 'letters': '\uA761' },
    { 'base': 'w', 'letters': '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73' },
    { 'base': 'x', 'letters': '\u0078\u24E7\uFF58\u1E8B\u1E8D' },
    { 'base': 'y', 'letters': '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF' },
    { 'base': 'z', 'letters': '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763' }
];

var diacriticsMap = {};
for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
    var letters = defaultDiacriticsRemovalMap[i].letters;
    for (var j = 0; j < letters.length; j++) {
        diacriticsMap[letters[j]] = defaultDiacriticsRemovalMap[i].base;
    }
}

// "what?" version ... http://jsperf.com/diacritics/12
export function removeDiacritics(str) {
    if (!str) {
        return str
    }

    return str.replace(/[^\u0000-\u007E]/g, function (a) {
        return diacriticsMap[a] || a;
    });
}   