const baseErrors = require('./base').RESPONSE_CODES;

const STATUS = Object.freeze({
    DISPUTE: 0,
    
    // Internal states
    // Between 50 - 99, are internal status and if changed in here, they must be changed collection filters.
    WAITING_PAYMENT: 50,
    QUEUED_TO_DELETION: 60,

    // Preparing states
    PREPARING_READY: 100,
    PREPARING_PROGRESS: 101,

    // Picking states
    PICKING_READY: 110,
    PICKING_PROGRESS: 111,
    PICKING_DONE: 112,

    // Consolidation states
    CONSOLIDATION_READY: 120,
    CONSOLIDATION_PROGRESS: 121,
    CONSOLIDATION_DONE: 122,

    // Order in transit
    TRANSIT: 200,

    // Order delivered
    DELIVERED: 250,

    CANCELED: 300,
    CANCELED_PRODUCER: 301
})

const DISPUTE_CODES = Object.freeze({
    NOT_ARRIVED: 0, //No ha arribat la meva comanda
    UNWANTED_PRODUCTS: 1, //El que he rebut no correspon amb el que vaig demanar
    BAD_STATE: 2,//Un o múltiples productes no estan en bon estat
    OTHER_DISPUTE: 3, //Alguna cosa diferent
})

const CANCEL_CODES = Object.freeze({
    MISSTAKE: 0,
    WRONG_ITEMS: 1,
    NO_STOCK: 2,
    INCORRECT_PRODUCTS: 3,
    TOOK_TOO_MUCH: 4,
    OTHER: 5
})

/**
 * WARNING!!!!!
 * 
 * UPDATE delivery form-validator if these values are updated
 */
const SENDING_TIME = Object.freeze({
    ONE_DAY:          1, // 24h
    ONE_TWO_DAYS:     2, // 24/48h
    THREE_FOUR_DAYS:  4, // 48/72h
    ONE_WEEK:         7 
})

const SENDING_TIME_LABEL = Object.freeze({
    [SENDING_TIME.ONE_DAY]:          '$common.codes.order.oneDay', // 24h
    [SENDING_TIME.ONE_TWO_DAYS]:     '$common.codes.order.oneTwoDays', // 24/48h
    [SENDING_TIME.THREE_FOUR_DAYS]:  '$common.codes.order.threeFourDays', // 48/72h
    [SENDING_TIME.ONE_WEEK]:         '$common.codes.order.week' 
})

/**
 * WARNING!!!!!
 * 
 * UPDATE delivery form-validator if these values are updated
 */
const SENDING_SCHEDULE = Object.freeze({
    MONDAY:     0,
    TUESDAY:    1,
    WEDNESDAY:  2,
    THURSDAY:   3, 
    FRIDAY:     4, 
    SATURDAY:   5, 
    SUNDAY:     6 
})

const SENDING_SCHEDULE_LABEL = Object.freeze({
    [SENDING_SCHEDULE.MONDAY]:          '$common.codes.order.monday', // 24h
    [SENDING_SCHEDULE.TUESDAY]:          '$common.codes.order.tuesday', // 24h
    [SENDING_SCHEDULE.WEDNESDAY]:          '$common.codes.order.wednesday', // 24h
    [SENDING_SCHEDULE.THURSDAY]:          '$common.codes.order.thursday', // 24h
    [SENDING_SCHEDULE.FRIDAY]:          '$common.codes.order.friday', // 24h
    [SENDING_SCHEDULE.SATURDAY]:          '$common.codes.order.saturday', // 24h
    [SENDING_SCHEDULE.SUNDAY]:     '$common.codes.order.sunday'
})

const SENDING_REDUCED_SCHEDULE_LABEL = Object.freeze({
    [SENDING_SCHEDULE.MONDAY]:          '$common.codes.order.shortDays.monday', // 24h
    [SENDING_SCHEDULE.TUESDAY]:          '$common.codes.order.shortDays.tuesday', // 24h
    [SENDING_SCHEDULE.WEDNESDAY]:          '$common.codes.order.shortDays.wednesday', // 24h
    [SENDING_SCHEDULE.THURSDAY]:          '$common.codes.order.shortDays.thursday', // 24h
    [SENDING_SCHEDULE.FRIDAY]:          '$common.codes.order.shortDays.friday', // 24h
    [SENDING_SCHEDULE.SATURDAY]:          '$common.codes.order.shortDays.saturday', // 24h
    [SENDING_SCHEDULE.SUNDAY]:     '$common.codes.order.shortDays.sunday'
})

const WEIGHT_SUPLEMENT_RANGES = Object.freeze({
    TWO_HALF: 0,
    FIVE: 1,
    TEN: 2
})

const WEIGHT_SUPLEMENT_RANGES_VALUES = Object.freeze({
    [WEIGHT_SUPLEMENT_RANGES.TWO_HALF]: 2500,
    [WEIGHT_SUPLEMENT_RANGES.FIVE]: 5000,
    [WEIGHT_SUPLEMENT_RANGES.TEN]: 1000
})

const WEIGHT_SUPLEMENT_RANGES_MESSAGES = Object.freeze({
    [WEIGHT_SUPLEMENT_RANGES.TWO_HALF]: '$common.codes.producer.twoHalf',
    [WEIGHT_SUPLEMENT_RANGES.FIVE]: '$common.codes.producer.five',
    [WEIGHT_SUPLEMENT_RANGES.TEN]: '$common.codes.producer.ten'
})

Object.freeze({
    MONDAY:     0,
    TUESDAY:    1,
    WEDNESDAY:  2,
    THURSDAY:   3, 
    FRIDAY:     4, 
    SATURDAY:   5, 
    SUNDAY:     6 
})

const RESPONSE_CODES = Object.freeze({
    ...baseErrors,
    INVALID_CARD:                4,
    PRODUCER_NOT_FOUND:          5,
    MINIMUM_NOT_REACHED:         6,
    PRODUCT_NOT_FOUND:           7,
    NEW_DELIVERY_PRICE:          8,
    NO_STOCK:                    9,
    PRICE_UPDATE:                10,
    AMOUNT_UPDATE:               11,
    AMOUNT_UNIT_UPDATE:          12,
    TOTAL_PRODUCER_MISMATCH:     13,
    DIRECTION_NOT_FOUND:         14,
    PAYMENT_ERROR:               15,
    UNABLE_STORE_PAYMENT:        16,
    ORDER_NOT_FOUND:             17,
    PAYMENT_NOT_DONE:            18,
    PRODUCER_COUNTER_NOT_UPDATED:19,
    ORDER_STATUS_NOT_CHANGED:    20,
    REFUND_ERROR:                21,
    EMAIL_ERROR :                22,
    USER_NOT_FOUND:              23,
    INVALID_FORM:                24,
    CANCEL_ORDER_NOT_PERMITED:   25,
    NO_DELIVERY_ZONE_SELECTED:   26,
    DELIVERY_ZONE_NOT_FOUND:     27,
    PICKUP_NOT_ENABLED:          28
})

const RESPONSE_MESSAGES = Object.freeze({
    [RESPONSE_CODES.OK]: '$common.codes.order.ok',
    [RESPONSE_CODES.ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.UNKNOWN_ERROR]: '$common.errors.unknown',
    [RESPONSE_CODES.INVALID_CARD]: '$common.codes.order.invalidCard',
    [RESPONSE_CODES.PRODUCER_NOT_FOUND]: '$common.codes.order.producerNotFound',
    [RESPONSE_CODES.MINIMUM_NOT_REACHED]: '$common.codes.order.minimumNotReached',
    [RESPONSE_CODES.PRODUCT_NOT_FOUND]: '$common.codes.order.productNotFound',
    [RESPONSE_CODES.NEW_DELIVERY_PRICE]: '$common.codes.order.newDeliveryPrice',
    [RESPONSE_CODES.NO_STOCK]: '$common.codes.order.noStock',
    [RESPONSE_CODES.PRICE_UPDATE]: '$common.codes.order.priceUpdate',
    [RESPONSE_CODES.AMOUNT_UPDATE]: '$common.codes.order.amountUpdate',
    [RESPONSE_CODES.AMOUNT_UNIT_UPDATE]: '$common.codes.order.amountUnitUpdate',
    [RESPONSE_CODES.TOTAL_PRODUCER_MISMATCH]: '$common.codes.order.totalProducerMismatch',
    [RESPONSE_CODES.DIRECTION_NOT_FOUND]: '$common.codes.order.directionNotFound',
    [RESPONSE_CODES.PAYMENT_ERROR]: '$common.codes.order.paymentError',
    [RESPONSE_CODES.ORDER_NOT_FOUND]: '$common.codes.order.orderNotFound',
    [RESPONSE_CODES.PAYMENT_NOT_DONE]: '$common.codes.order.paymentNotDone',
    [RESPONSE_CODES.PRODUCER_COUNTER_NOT_UPDATED]: '$common.errors.unknown',
    [RESPONSE_CODES.REFUND_ERROR]: '$common.codes.order.refundError',
    [RESPONSE_CODES.USER_NOT_FOUND]: '$common.codes.order.userNotFound',
    [RESPONSE_CODES.CANCEL_ORDER_NOT_PERMITED]: '$common.codes.order.cancelNotPermitted',
    [RESPONSE_CODES.PICKUP_NOT_ENABLED]: '$common.codes.order.pickupNotEnabled'
})

export {
    STATUS,
    RESPONSE_CODES,
    RESPONSE_MESSAGES,
    DISPUTE_CODES,
    CANCEL_CODES,
    SENDING_TIME,
    SENDING_TIME_LABEL,
    SENDING_REDUCED_SCHEDULE_LABEL,
    SENDING_SCHEDULE,
    SENDING_SCHEDULE_LABEL,
    WEIGHT_SUPLEMENT_RANGES,
    WEIGHT_SUPLEMENT_RANGES_VALUES,
    WEIGHT_SUPLEMENT_RANGES_MESSAGES
}
