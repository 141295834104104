
export function object_equals(x, y) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal

        if (typeof (x[p]) !== "object") return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!object_equals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (p in y)
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p))
            return false;
    // allows x[ p ] to be set to undefined
    return true;
}

/**
 * RECURSIVE *
 * 
 * Returns the positiveDiff between 2 objects. (additions + modifications) and not deletions
 * 
 * i.e:
 * 
 * given:
 * oldObject = {
 *  "a": 'hi',
 *  "b": {"b": 'hi},
 *  "c": 'hi'
 * }
 * newObject = {
 *  "b": {"b": 'bye},
 *  "c": 'hi,
 *  "d": 'hi'
 * }
 * 
 * returns:
 * 
 * {
 *  diff: {
 *    "b": {"b": 'bye}
 *    "d": 'hi'
 *  }
 *  isEqual: false
 * }
 * 
 * ifDiffAlsoInclude example:
 * 
 * {
 *      "field1": {
 *          "field2": true,
 *          "field3": false
 *       }
 * } // If field1 is diff, then field2 will also be included
 * 
 * 
 * 
 * @param {*} oldObject 
 * @param {*} newObject 
 * @param {*} ignoreFields Optional parameter to avoid and igonre fields
 * @param {*} ifDiffIncludeAllFields Optinal parameter to include the entire object if any nested field value is not equal
 * @param {*} ifDiffAlsoInclude Optinal parameter to include other fields if value is not equal
 */
export function positiveObjectDiff(oldObject, newObject, ignoreFields = [], ifDiffIncludeAllFields = [], ifDiffAlsoInclude = {}) {

    if (typeof newObject !== typeof oldObject) {
        return false
    }

    let diff = {}
    let isEqual = true;

    for (let field in newObject) {
        let iterationEqual = true
        if (ignoreFields.includes(field))
            continue;

        if ((newObject[field] == null || typeof newObject[field] === 'undefined') && (oldObject[field] == null || typeof oldObject[field] === 'undefined')) {
            continue;
        }

        if (typeof oldObject[field] != typeof newObject[field] || oldObject[field] == null) {
            diff[field] = newObject[field]
            iterationEqual = isEqual = false;
            continue;
        }

        switch (typeof newObject[field]) {

            case 'object':
                if (Array.isArray(newObject[field])) {

                    if (newObject[field].length != oldObject[field].length) {
                        diff[field] = newObject[field]
                        iterationEqual = isEqual = false
                        break;
                    }

                    for (let i in newObject[field]) {

                        let found = undefined

                        if (typeof newObject[field][i] === 'object') {
                            found = oldObject[field].find((value) => object_equals(value, newObject[field][i]))
                        } else {
                            found = oldObject[field].find((value) => value === newObject[field][i])
                        }

                        if (typeof found === 'undefined') {
                            diff[field] = newObject[field]
                            iterationEqual = isEqual = false
                            break;
                        }

                    }

                } else {

                    let result = positiveObjectDiff(oldObject[field], newObject[field], ignoreFields, ifDiffIncludeAllFields, ifDiffAlsoInclude)

                    if (!result.isEqual) {
                        iterationEqual = isEqual = false

                        if (ifDiffIncludeAllFields.includes(field)) {
                            diff[field] = newObject[field]
                        } else {
                            diff[field] = result.diff
                        }

                    }

                }
                break;
            case 'boolean':
            case 'number':
            case 'bigint':
            case 'string':

                if (newObject[field] != oldObject[field]) {
                    diff[field] = newObject[field]
                    iterationEqual = isEqual = false
                }
                break;

            case 'function':
                if ('' + newObject[field] != '' + oldObject[field]) {
                    diff[field] = newObject[field]
                    iterationEqual = isEqual = false
                }
                break;
            case 'undefined':
            case 'symbol':
            default:
                //Imposible or very rare cases
                break;
        }

        if (!iterationEqual && typeof ifDiffAlsoInclude[field] != 'undefined') {
            for (let alsoField in ifDiffAlsoInclude[field]) {
                if (ifDiffAlsoInclude[field][alsoField] === true)
                    diff[alsoField] = newObject[alsoField]
            }
        }

    }

    return { diff, isEqual }

}
/**
 * Searches what has been deleted
 * @param {} oldObject 
 * @param {*} newObject 
 * @param {*} ignoreFields 
 * @param {*} ifDiffIncludeAllFields 
 * @param {*} ifDiffAlsoInclude 
 */
export function negativeObjectDiff(oldObject, newObject, ignoreFields = [], ifDiffIncludeAllFields = [], ifDiffAlsoInclude = {}) {

    if (typeof newObject !== typeof oldObject) {
        return false
    }

    let diff = {}
    let isEqual = true;

    for (let field in oldObject) {
        if (ignoreFields.includes(field))
            continue;

        if ((newObject[field] == null || typeof newObject[field] === 'undefined') && (oldObject[field] == null || typeof oldObject[field] === 'undefined')) {
            continue;
        }

        if (newObject[field] == null) {
            diff[field] = null
            isEqual = false;
            continue;
        }
        if (typeof newObject[field] === 'object') {
            if (Array.isArray(newObject[field])) {
                // Handled entirely by positiveObjectDiff
                // For the current use case
            } else {

                let result = negativeObjectDiff(oldObject[field], newObject[field], ignoreFields, ifDiffIncludeAllFields, ifDiffAlsoInclude)

                if (!result.isEqual) {
                    isEqual = false
                    diff[field] = result.diff
                }

            }
        }
        //ELSE
        // Handled by positiveObjectDiff
        // This method only track deleted
    }

    return { diff, isEqual }

}

export function translationsAwareObjectDiff(oldObject, newObject, ignoreFields = [], ifDiffIncludeAllFields = [], ifDiffAlsoInclude = {}) {
    let first = positiveObjectDiff(oldObject, newObject, ignoreFields, ifDiffIncludeAllFields, ifDiffAlsoInclude);
    let deletions = negativeObjectDiff(oldObject, newObject, ignoreFields, ifDiffIncludeAllFields, ifDiffAlsoInclude);

    let translateDeleted = false
    if (typeof deletions.diff['translations'] != 'undefined') {
        translateDeleted = true
    }

    let diff = first.diff
    let isEqual = first.isEqual && deletions.isEqual
    return { diff, isEqual, translateDeleted }
}


export function arrayFromObjectsKey(arrayOfObjects, keyOfObject) {

    let array = []

    for (let object of arrayOfObjects) {
        if (typeof object[keyOfObject] !== 'undefined')
            array.push(object[keyOfObject])
    }

    return array

}

export function removeField(object, toRemoveField) {

    for (let field in object) {
        if (field == toRemoveField) {
            delete object[field]
            continue
        }

        if (typeof object[field] === 'object') {
            removeField(object[field], toRemoveField)
        }
    }
}

export const getProp = (object, path) => {
    if (path.length === 1) return object[path[0]];
    else if (path.length === 0) throw error;
    else {
        if (object[path[0]]) return getProp(object[path[0]], path.slice(1));
        else {
            object[path[0]] = {};
            return getProp(object[path[0]], path.slice(1));
        }
    }
};

export const getProps = (object, fields, translations = undefined) => {
    var newObject = {}
    if (typeof object === 'undefined' || object === null) return {}
    fields.forEach(field => {
        if (typeof object[field] === 'undefined' || object[field] === null) return;

        newObject[(typeof translations !== 'undefined' &&
            translations !== null &&
            typeof translations[field] !== 'undefined' &&
            translations[field] !== null) ? translations[field] : field] = object[field]
    })

    return newObject
}

export const addReplaceChanges = (object, changes) => {

    Object.keys(changes).forEach(field => {

        if (typeof changes[field] === 'object' &&
            changes[field] != null) {
            if (typeof object[field] === 'undefined') object[field] = {}
            addReplaceChanges(object[field], changes[field])

        } else if (changes[field] != null) {
            object[field] = changes[field]
        }

    })

}

export const isAllNull = (object, omit = []) => {

    if (typeof object === 'undefined') return true;
    let allNull = true;

    Object.keys(object).forEach(key => {
        if (omit.includes(key)) {
            return
        }
        allNull = object[key] != null ? false : allNull;
    })
    return allNull;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}